import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {MenuItem, MenuItemCommandEvent} from "primereact/menuitem";
import {useLocation, useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import {useAppDispatch} from "../../lib/hooks/useAppDispatch";
import {Api} from "../../lib/services/mainApi";
import {logout} from "../../lib/store/slices/authSlice";
import "./hamburger.css"
import {it} from "node:test";

interface Props {

}

export const Navigation: React.FC<Props> = props => {
    const didMountRef = useRef(false);
    const {formatMessage: f} = useIntl();
    const dispatch = useAppDispatch();
    const api = new Api();

    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
    const mobileOverlayMenuRef = useRef<any>(null);


    // const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
    //     listener: (event) => {
    //         setEventData({
    //             width: event.currentTarget.innerWidth,
    //             height: event.currentTarget.innerHeight,
    //         })
    //     }
    // });


    const {pathname} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const getMainMenuActiveClass = (module: string) => {
        return pathname.includes(module) ? 'active' : '';
    }

    const handleLogout = () => {
        api.auth.logout()
            .then(resp => {
                if (resp.data)
                    dispatch(logout());
            })
    };

    const navButtonTemplate = (item: MenuItem) => {
        return <a key={item.id} className={`nav-item ${item.className} ${getMainMenuActiveClass(item.id || "")}`} onClick={(e) => {
            if (item.command) item.command(e as unknown as MenuItemCommandEvent);
        }}>
            <span className="mx-2">{item.label}</span>
        </a>
    }

    const profileTemplate = (item: MenuItem) => {
        return <div key={item.id} className={"flex align-items-center p-2 mr-3"} onClick={handleLogout}>
            <i className={"fal fa-sign-out font-bold"}/>
        </div>
    }

    const administrationTemplate = (item: MenuItem) => {
        return <div key={item.id} className={`nav-item flex align-items-center p-2 mr-2 ${getMainMenuActiveClass(item.id || "")}`}
                    onClick={() => navigate("/administration")}>
            <i className={"fal fa-gear font-bold"}/>
        </div>
    }

    const mainItems: MenuItem[] = [
        {
            id: "diseases",
            label: f({id: "diseases"}),
            className: `nav-item ml-auto mr-2`,
            command: () => navigate('/diseases'),
            template: navButtonTemplate
        },
        {
            id: "clinicalPictures",
            label: f({id: "clinicalPictures"}),
            className: `nav-item mr-2`,
            command: () => navigate('/clinicalPictures'),
            template: navButtonTemplate
        },
        // {
        //     id: "administration",
        //     label: f({id: "administration"}),
        //     className: `nav-item ${getMainMenuActiveClass('administration')}`,
        //     command: () => navigate('/administration'),
        //     template: navButtonTemplate
        // },
        {
            id: "administration",
            label: f({id: "administration"}),
            className: `nav-item`,
            command: () => navigate("/administration"),
            template: administrationTemplate.bind(undefined)
        },
        {
            id: "logout",
            label: f({id: "logout"}),
            className: `nav-item mr-4`,
            template: profileTemplate.bind(undefined),
            command: handleLogout
        }
    ]

    const getMenuItems = () => {
        //TODO:
        // if (!userInfo.authenticated || userInfo.shouldChangePassword) {
        //     return loginItems;
        // }
        return mainItems;
    }

    // const getSubItems = (): MenuItem[] => {
    //     //TODO:
    //     // if (!userInfo.authenticated || userInfo.shouldChangePassword) {
    //     //     return [];
    //     // }
    //
    //     return [];
    // }

    // const handleMenuItemMouseOver = (event: MouseEvent) => {
    //     const target = event.target as HTMLElement;
    //     const offersMenuItem = document.querySelector("#offers-menu-item");
    //     if (offersMenuItem && offersMenuItem.contains(target) && offersOverlayMenuRef.current) {
    //         offersOverlayMenuRef.current.show(event);
    //     }
    // }

    const handleHamburgerButtonClick = () => {

        console.log(mobileOverlayMenuRef.current);
        if (mobileOverlayMenuRef.current) {
            mobileOverlayMenuRef.current.classList.toggle("slide-out");
            mobileOverlayMenuRef.current.classList.toggle("slide-in");
            if (mobileOverlayMenuRef.current.classList.contains("slide-out")) {
                setTimeout(() => {
                    mobileOverlayMenuRef.current.classList.add("display-none");
                }, 1000);
            } else {
                mobileOverlayMenuRef.current.classList.remove("display-none");
            }
        }

        setIsHamburgerOpen(!isHamburgerOpen)
    }

    return <>
        <nav className={'navbar fixed-top fixed-top-mainmenu bg-white shadow-1'}>
            <div className="navbar-container bg-white h-full">
                <a className="navbar-brand lg:ml-8" data-qa="homeLink" onClick={() => navigate('/')}>
                    <img style={{height: "100%"}} src="/logo.svg" width="112" height="80" alt="logo"/>
                </a>
                {/*<h2>DocPoint</h2>*/}


                <div className={'navbar-menu-bar border-none hidden md:flex'}>
                    {getMenuItems().map(el => {
                        //@ts-ignore
                        if (el.template) return el.template(el);
                        return <div key={el.id}>{el.label}</div>
                    })}
                </div>

                <div className={`md:hidden flex ${isHamburgerOpen ? "open" : ""}`} id="nav-icon3"
                     onClick={handleHamburgerButtonClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {/*<div className="mobile-nav">*/}
                {/*</div>*/}

                {/*<Menubar model={getMenuItems()} className={'navbar-menu-bar border-none'}*/}
                {/*         // pt={{menuitem: {onMouseOver: handleMenuItemMouseOver}}}*/}
                {/*/>*/}
            </div>
        </nav>
        <ul ref={mobileOverlayMenuRef} className="mobile-menu slide-out display-none">
            {getMenuItems().map(el => {
                return <li key={el.id} className={`mobile-menu-li ${getMainMenuActiveClass(el.id || '')}`} onClick={(e) => {
                    handleHamburgerButtonClick();
                    el.command!(e as unknown as MenuItemCommandEvent)
                }}>{el.label}</li>

            })}
        </ul>

        {/*<nav className={'navbar navbar-expand-md navbar-light bg-white fixed-top fixed-top-submenu'}>*/}
        {/*    <div className="container flex justify-content-space-between">*/}

        {/*        {getSubItems().length > 0 &&*/}
        {/*            <Menubar model={getSubItems()} className={'navbar-submenu-bar'}*/}
        {/*                     pt={{action: {className: 'nav-link'}}}/>*/}
        {/*        }*/}
        {/*    </div>*/}
        {/*</nav>*/}
    </>
};
