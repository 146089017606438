import 'primereact/resources/themes/lara-light-teal/theme.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import "./layout/fontawesome6/pro/css/all.min.css";
import "./lib/styles/navbar.css";
import "./lib/styles/sub-navbar.css";
import "./lib/styles/app.css";
import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {useIntl} from "react-intl";
import {configureApp} from "./lib/configureApp";
import {useAppSelector} from "./lib/hooks/useAppSelector";
import {Navigation} from "./layout/navigation/Navigation";
import {Api} from "./lib/services/mainApi";
import {useAppDispatch} from "./lib/hooks/useAppDispatch";
import {authenticate} from "./lib/store/slices/authSlice";
import {useToast} from "./lib/hooks/useToast";
const Home = React.lazy(() => import("./components/Home"));
const Login = React.lazy(() => import("./components/login/Login"));
const NotFound = React.lazy(() => import("./components/404/NotFound"));
const Diseases = React.lazy(() => import("./components/diseases/Diseases"));
const ClinicalPictureTypes = React.lazy(() => import("./components/clinicalPictureTypes/ClinicalPictureTypes"));
const Administration = React.lazy(() => import("./components/administration/Administration"));
const ClinicalPictures = React.lazy(() => import("./components/clinicalPictures/ClinicalPictures"));


export const App = () => {
    const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
    const {formatMessage: f} = useIntl();
    const dispatch = useAppDispatch();
    const {showToast} = useToast();

    configureApp(f, showToast);

    // const sidebarItems: SidebarItem[] = [
    //     {id: "home", className: 'pi pi-home', label: f({id: "home"}), command: () => navigate('/')}
    // ]
    //
    // const itemPathsMap = {
    //     '^\\/$': 'home'
    // }

    useEffect(() => {
        const api = new Api();
        api.auth.checkIfAuthenticated()
            .then(resp => {
                if(resp) dispatch(authenticate())
            })
            .catch((err) => 0)
    }, [])

    useEffect(() => {
        if(isAuthenticated) document.body.style.height = "calc(100% - 95px)";
        else document.body.style.height = "100%";
    }, [isAuthenticated])



    return <>
        {!isAuthenticated ? <Login/> :
            <>
                <Navigation/>
                <React.Suspense fallback={<>Loading...</>}>
                    <main>
                        <div className={'container m-1 lg:m-4'}>
                            <Routes>
                                <Route index element={<Home/>}/>
                                <Route path={"/clinicalPictures"} element={<ClinicalPictures />} />
                                <Route path={"/clinicalPictureTypes"} element={<ClinicalPictureTypes />} />
                                <Route path={"/diseases"} element={<Diseases />} />
                                <Route path={"/administration"} element={<Administration/>} />

                                <Route path="*" element={<NotFound/>}/>
                            </Routes>
                        </div>
                    </main>

                </React.Suspense>
            </>
        }
    </>
}

export default App;
