import {axiosInstances} from "../axios"
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ClinicalPictureDto {
  /** @format int32 */
  id?: number;
  nameBg?: string | null;
  nameEn?: string | null;
  diseases?: DiseaseDto[] | null;
  /** @format int32 */
  clinicalPictureTypeId?: number;
}

export interface ClinicalPictureTypeDto {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
}

export interface CouldBeDeletedResponse {
  couldBeDeleted?: boolean;
  associatedClinicalPictureNames?: string[] | null;
}

export interface CreateClinicalPictureDto {
  nameBg?: string | null;
  nameEn?: string | null;
  diseaseIds?: number[] | null;
  /** @format int32 */
  clinicalPictureTypeId?: number;
}

export interface CreateClinicalPictureTypeDto {
  name?: string | null;
  description?: string | null;
}

export interface CreateDiseaseDto {
  nameBg?: string | null;
  nameEn?: string | null;
  clinicalPictureIds?: number[] | null;
}

export interface DiseaseDto {
  /** @format int32 */
  id?: number;
  nameBg?: string | null;
  nameEn?: string | null;
  clinicalPictures?: ClinicalPictureDto[] | null;
}

export interface DropdownItem {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  key?: number;
  description?: string | null;
}

export interface DropdownItemWithGroup {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  key?: number;
  description?: string | null;
  groupName?: string | null;
}

export interface HttpValidationProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  errors?: Record<string, string[]>;
  [key: string]: any;
}

export interface LoginRequestDto {
  username?: string | null;
  password?: string | null;
}

export interface UpdateClinicalPictureDto {
  nameBg?: string | null;
  nameEn?: string | null;
  diseaseIds?: number[] | null;
  /** @format int32 */
  clinicalPictureTypeId?: number;
  /** @format int32 */
  id?: number;
}

export interface UpdateClinicalPictureTypeDto {
  name?: string | null;
  description?: string | null;
  /** @format int32 */
  id?: number;
}

export interface UpdateDiseaseDto {
  nameBg?: string | null;
  nameEn?: string | null;
  clinicalPictureIds?: number[] | null;
  /** @format int32 */
  id?: number;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axiosInstances.mainAxios;
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title docPoint_API
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name Login
     * @request POST:/auth/login
     */
    login: (data: LoginRequestDto, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/auth/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @request POST:/auth/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/auth/logout`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name CheckIfAuthenticated
     * @request GET:/auth/checkIfAuthenticated
     */
    checkIfAuthenticated: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/auth/checkIfAuthenticated`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  clinicalPictures = {
    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name GetClinicalPictures
     * @request GET:/clinicalPictures
     */
    getClinicalPictures: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClinicalPictureDto[], void>({
        path: `/clinicalPictures`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name CreateClinicalPicture
     * @request POST:/clinicalPictures
     */
    createClinicalPicture: (data: CreateClinicalPictureDto, params: RequestParams = {}) =>
      this.request<ClinicalPictureDto, HttpValidationProblemDetails | void>({
        path: `/clinicalPictures`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name UpdateClinicalPicture
     * @request PUT:/clinicalPictures
     */
    updateClinicalPicture: (data: UpdateClinicalPictureDto, params: RequestParams = {}) =>
      this.request<boolean, HttpValidationProblemDetails | void>({
        path: `/clinicalPictures`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name GetClinicalPicturesDropdown
     * @request GET:/clinicalPictures/dropdown
     */
    getClinicalPicturesDropdown: (params: RequestParams = {}) =>
      this.request<DropdownItemWithGroup[], void>({
        path: `/clinicalPictures/dropdown`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name GetClinicalPicture
     * @request GET:/clinicalPictures/{id}
     */
    getClinicalPicture: (id: number, params: RequestParams = {}) =>
      this.request<ClinicalPictureDto, void>({
        path: `/clinicalPictures/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictures
     * @name DeleteClinicalPicture
     * @request DELETE:/clinicalPictures/{id}
     */
    deleteClinicalPicture: (id: number, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/clinicalPictures/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  clinicalPictureTypes = {
    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name GetClinicalPictureTypes
     * @request GET:/clinicalPictureTypes
     */
    getClinicalPictureTypes: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClinicalPictureTypeDto[], void>({
        path: `/clinicalPictureTypes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name CreateClinicalPictureType
     * @request POST:/clinicalPictureTypes
     */
    createClinicalPictureType: (data: CreateClinicalPictureTypeDto, params: RequestParams = {}) =>
      this.request<ClinicalPictureTypeDto, HttpValidationProblemDetails | void>({
        path: `/clinicalPictureTypes`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name UpdateClinicalPictureType
     * @request PUT:/clinicalPictureTypes
     */
    updateClinicalPictureType: (data: UpdateClinicalPictureTypeDto, params: RequestParams = {}) =>
      this.request<boolean, HttpValidationProblemDetails | void>({
        path: `/clinicalPictureTypes`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name GetClinicalPictureTypesDropdown
     * @request GET:/clinicalPictureTypes/dropdown
     */
    getClinicalPictureTypesDropdown: (params: RequestParams = {}) =>
      this.request<DropdownItem[], void>({
        path: `/clinicalPictureTypes/dropdown`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name GetClinicalPictureType
     * @request GET:/clinicalPictureTypes/{id}
     */
    getClinicalPictureType: (id: number, params: RequestParams = {}) =>
      this.request<ClinicalPictureTypeDto, void>({
        path: `/clinicalPictureTypes/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name DeleteClinicalPictureType
     * @request DELETE:/clinicalPictureTypes/{id}
     */
    deleteClinicalPictureType: (id: number, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/clinicalPictureTypes/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClinicalPictureTypes
     * @name CheckIfCouldBeDeleted
     * @request GET:/clinicalPictureTypes/checkIfCouldBeDeleted/{id}
     */
    checkIfCouldBeDeleted: (id: number, params: RequestParams = {}) =>
      this.request<CouldBeDeletedResponse, any>({
        path: `/clinicalPictureTypes/checkIfCouldBeDeleted/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  diseases = {
    /**
     * No description
     *
     * @tags Diseases
     * @name GetDiseases
     * @request GET:/diseases
     */
    getDiseases: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DiseaseDto[], void>({
        path: `/diseases`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Diseases
     * @name CreateDisease
     * @request POST:/diseases
     */
    createDisease: (data: CreateDiseaseDto, params: RequestParams = {}) =>
      this.request<DiseaseDto, HttpValidationProblemDetails | void>({
        path: `/diseases`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Diseases
     * @name UpdateDisease
     * @request PUT:/diseases
     */
    updateDisease: (data: UpdateDiseaseDto, params: RequestParams = {}) =>
      this.request<boolean, HttpValidationProblemDetails | void>({
        path: `/diseases`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Diseases
     * @name GetDiseasesDropdown
     * @request GET:/diseases/dropdown
     */
    getDiseasesDropdown: (params: RequestParams = {}) =>
      this.request<DropdownItem[], void>({
        path: `/diseases/dropdown`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Diseases
     * @name GetDisease
     * @request GET:/diseases/{id}
     */
    getDisease: (id: number, params: RequestParams = {}) =>
      this.request<DiseaseDto, void>({
        path: `/diseases/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Diseases
     * @name DeleteDisease
     * @request DELETE:/diseases/{id}
     */
    deleteDisease: (id: number, params: RequestParams = {}) =>
      this.request<boolean, void>({
        path: `/diseases/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
}
